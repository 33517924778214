<template>
  <div>
    <title>SIM INVENTORY ~ ADD PRODUCTION PROCESS DATA MATERIAL DETAILS</title>
    <section class="content-header">
      <h1>
        Add Production Process Data Material Details
        <br />
        <h4>
          Please enter the transaction data for the production process material
          details
        </h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Master Proses Produksi Detail Material</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">
                Save Process Data Produksi Detail Material
              </h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Batch Code</label>
                  <input
                    type="text"
                    v-model="isidata.prodnumb"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Batch Code"
                  />
                  <input
                    type="hidden"
                    v-model="isidata.idxoutgoingd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.notesprosesprodm"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="hidden"
                    v-model="isidata.qtyprosesdmi"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Qty"
                  />
                  <input
                    type="number"
                    v-model="isidata.qtyprosesdm"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Qty"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/outgoing">
                  <button class="btn btn-success">
                    <i class="fa fa-angle-double-left"></i> Back
                  </button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdata",
  data() {
    return {
      loading: false,
      isidata: {
        prodnumb: "",
        idxoutgoingd: "",
        notesprosesprodm: "",
        qtyprosesdm: "",
        qtyprosesdmi: ""
      }
    };
  },
  created() {
    this.isidata.idxoutgoingd = this.$route.params.id;
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "proses_produksi_d_material/getstok_material_di_produksibyid?id=" +
        this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.isidata.qtyprosesdmi = resp.data.data.stok;
          this.isidata.qtyprosesdm = resp.data.data.stok;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          }).then(function(isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        });
    },
    async saveData() {
      this.loading = true;
      var prodnumber = this.isidata.prodnumb;
      var catatan = this.isidata.notesprosesprodm;
      var qty = this.isidata.qtyprosesdm;
      if (prodnumber == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Production Number can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catatan == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes Production Process Material can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (qty == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty Production Process Material can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        if (this.isidata.qtyprosesdm > this.isidata.qtyprosesdmi) {
          swal({
            icon: "warning",
            title: "Warning",
            text: "Sorry, the amount you entered exceeds the existing stock",
            showConfirmButton: false
          });
          this.loading = false;
        } else {
          var kodeuser = sessionStorage.getItem("kodeuser");
          const paramdata = {
            nomor_produksi: this.isidata.prodnumb,
            id_outgoing_d: this.isidata.idxoutgoingd,
            notes: this.isidata.notesprosesprodm,
            qty: this.isidata.qtyprosesdm,
            kode_user: kodeuser
          };
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          const urlAPISaveData =
            this.$apiurl +
            "proses_produksi_d_material/saveproses_produksi_d_material";
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .post(urlAPISaveData, paramdata, { headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Save data successfully",
                  showConfirmButton: false
                });
                this.$router.push({ name: "prosesproduksiheader" });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Save data failed",
                  showConfirmButton: false
                });
                this.loading = false;
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data,
                showConfirmButton: false
              });
              console.log(err);
              this.loading = false;
            });
        }
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
